import {TextField} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';

export const EmailField = ({
  dataHook,
  className,
  label,
  value,
  hasError,
  onChange,
}: {
  dataHook: string;
  className: string;
  label: string;
  value?: string;
  hasError: boolean;
  onChange: (value: string) => void;
}) => {
  const localeKeys = useLocaleKeys();
  return (
    <TextField
      data-hook={dataHook}
      className={className}
      label={label}
      value={value}
      required
      error={hasError}
      errorMessage={localeKeys.checkout.emailField.input.error.message()}
      newErrorMessage={true}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
